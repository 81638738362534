// AXIOS
import apiClient from '../../libs/apiClient';

const getKeys = (params) => apiClient.get('/api-keys', { params });

const refreshKey = (id) => apiClient.put(`/api-keys/${id}`);

const apiKeyService = Object.freeze({
  getKeys,
  refreshKey
});

export default apiKeyService;
