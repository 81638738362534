// AXIOS
import apiClient from '../../libs/apiClient';
import {objectToURL} from '../../helpers/query';
import pathsJoin from '../../helpers/fp/url/pathsJoin';
import {BILLING_SETTING_PATH} from '../billingSetting';
import trap from '../../helpers/fp/decorators/trap';

export const USER_PATH = 'users';

const suspendUser = ({id}) => apiClient.put(
  pathsJoin([USER_PATH, id, 'suspend']));
const activateUser = ({id}) => apiClient.put(
  pathsJoin([USER_PATH, id, 'activate']));
const deactivateUser = ({id}) => apiClient.put(
  pathsJoin([USER_PATH, id, 'deactivate']));
const deleteUser = ({id}) => apiClient.delete(
  pathsJoin([USER_PATH, id, 'delete']));
const getPaymentsMethods = ({id}) => apiClient.get(
  pathsJoin([USER_PATH, id, 'payment-methods']));
const getUsers = (params) => apiClient.get(USER_PATH, {params});
const getDeletionRequests = (params) => apiClient.get(
  `/deletion-requests?${objectToURL(params)}`);
const getUser = ({id}) => apiClient.get(`/users/${id}`);
const createUser = (data) => apiClient.post(`/users`, data);
const getUserById = trap(
  (userId) => apiClient.get(pathsJoin([USER_PATH, userId])));
const deleteTenantUser = (id) => apiClient.delete(`/users/tenant-users/${id}`);

const getUserByBillingSettingId = (billingSettingId) => apiClient.get(
  pathsJoin([BILLING_SETTING_PATH, billingSettingId, 'user']));

const userService = Object.freeze({
  suspendUser,
  activateUser,
  deactivateUser,
  deleteUser,
  getPaymentsMethods,
  getUsers,
  getUser,
  getUserById,
  getDeletionRequests,
  getUserByBillingSettingId,
  createUser,
  deleteTenantUser,
});

export default userService;
