// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import apiKeyService from '../../services/apiKeys';

const apiKeyAsyncActions = Object.freeze({
  getKeys: createAsyncThunk('get/keys', apiKeyService.getKeys),
  refreshKey: createAsyncThunk('put/key', apiKeyService.refreshKey),
});

export default apiKeyAsyncActions;
