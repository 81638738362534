// CORE
import React from 'react';
// CONSTANTS
import {adminRoles} from '../../../../constants/roles';
// MUI
import GroupsIcon from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import StorageIcon from '@mui/icons-material/Storage';
import DiscountIcon from '@mui/icons-material/Discount';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupIcon from '@mui/icons-material/Group';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ComputerIcon from '@mui/icons-material/Computer';
import AccountBalance from '@mui/icons-material/AccountBalance';
import CloudIcon from '@mui/icons-material/Cloud';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import KeyIcon from '@mui/icons-material/Key';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ContrastIcon from '@mui/icons-material/Contrast';
import LineWeightIcon        from '@mui/icons-material/LineWeight';

export const menuList = [
  {
    label   : 'Server Logs',
    link    : '/logs',
    icon    : <MonitorHeartIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Deletion Requests',
    link    : '/deletion-requests',
    icon    : <PersonRemoveIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Tenants',
    link    : '/tenants',
    icon    : <AdminPanelSettingsIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Users',
    link    : '/users',
    icon    : <GroupIcon/>,
    children: [
      {
        step  : 1,
        name  : 'User Details',
        link  : '/users/:id',
        params: [ 'id' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN, adminRoles.TENANT_ADMIN ],
  },
  {
    label   : 'Teams',
    link    : '/teams',
    icon    : <GroupsIcon/>,
    children: [
      {
        step  : 1,
        name  : 'Team Details',
        link  : '/teams/:id',
        params: [ 'id' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Resources',
    link    : '/resources',
    icon    : <ComputerIcon/>,
    children: [
      {
        step  : 1,
        name  : 'Resource Details',
        link  : '/resources/:resourceId',
        params: [ 'resourceType', 'resourceId' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Billing Settings',
    link    : '/billing-settings',
    icon    : <AccountBalance/>,
    children: [
      {
        step  : 1,
        name  : 'Billing Setting Details',
        link  : '/billing-settings/:billingSettingId',
        params: [ 'billingSettingId' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Server Modes',
    link    : '/server-modes',
    icon    : <StorageIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Quotas',
    link    : '/quotas',
    icon    : <CloudDoneIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Invoices',
    link    : '/invoices',
    icon    : <MonetizationOnIcon/>,
    children: [
      {
        step  : 1,
        name  : 'Invoice Details',
        link  : '/invoices/:id',
        params: [ 'id' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Providers',
    link    : '/providers',
    icon    : <CloudIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Coupons',
    link    : '/coupons',
    icon    : <LocalOfferIcon/>,
    children: [
      {
        step  : 1,
        name  : 'Coupon Details',
        link  : '/coupons/:couponId',
        params: [ 'couponId' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Promo codes',
    link    : '/promos',
    icon    : <DiscountIcon/>,
    children: [
      {
        step  : 1,
        name  : 'Promo Code Details',
        link  : '/promos/:promoId',
        params: [ 'promoId' ],
      } ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Images',
    link    : '/images',
    icon    : <PlaylistAddCircleIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label: "Sizes",
    link: "/sizes",
    icon: <LineWeightIcon />,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'API keys',
    link    : '/api-keys',
    icon    : <KeyIcon/>,
    children: [],
    roles   : [ adminRoles.TENANT_ADMIN ],
  },
  {
    label   : 'Theme UI',
    link    : '/themes',
    icon    : <ContrastIcon/>,
    children: [],
    roles   : [ adminRoles.TENANT_ADMIN ],
  },
  {
    label   : 'Settings',
    link    : '/settings',
    icon    : <SettingsApplicationsIcon/>,
    children: [],
    roles   : [ adminRoles.TENANT_ADMIN, adminRoles.SUPER_ADMIN ],
  },
];

export const routeMapping = Object.freeze({
  [adminRoles.SUPER_ADMIN] : [
    '/logs',
    '/deletion-requests',
    '/users',
    '/users/:id',
    '/teams',
    '/teams/:id',
    '/resources',
    '/resources/:resourceId',
    '/billing-settings',
    '/billing-settings/:billingSettingId',
    '/server-modes',
    '/quotas',
    '/invoices',
    '/invoices/:id',
    '/providers',
    '/coupons',
    '/coupons/:couponId',
    '/promos',
    '/promos/:promoId',
    '/images',
    '/sizes',
    '/tenants',
    '/settings',
  ],
  [adminRoles.TENANT_ADMIN]: [
    '/users',
    '/users/:id',
    '/api-keys',
    '/themes',
    '/settings',
  ],
});
