// CORE
import React, {useEffect, useMemo} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// MUI
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
// STYLES
import './dashBoard.scss';
// STORE
import adminStore from '../../../store/admin';
import {selectAdmin} from '../../../store/admin/selector';
// COMPONENTS
import {menuList, routeMapping} from './constants';
import publicUrl from '../../../helpers/publicUrl';
import PublicLink from '../../shared/Link';
// HOOKS
import {useServerLogs} from '../../../hooks/useServerLogs/useServerLogs';
// CONSTANTS
import {jwtTokens} from '../../../constants/auth/tokens';

const publicItem = ({ link, children, ...menuItem }) => ({
  ...menuItem,
  link    : publicUrl(link),
  children: (children && Array.isArray(children)) ?
    children.map(child => publicItem(child)) :
    children || null,
});

const currentMenuList = menuList.map(publicItem);

const DashboardLayout = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector(selectAdmin);

  useServerLogs();

  const menus = useMemo(
    () => menuList.filter(link => link.roles.includes(admin.role)),
    [ admin.role ]);

  const currentPath = Object.entries(params).reduce((prev, [ key, value ]) => {
    return prev.replace(value, `:${key}`);
  }, location.pathname);

  useEffect(() => {
    const isAvailablePath = routeMapping[admin.role].includes(currentPath);
    if (!isAvailablePath) navigate(publicUrl('/'));
  }, [ currentPath, admin ]);

  const currentRoute = currentMenuList.find(item => item.link === currentPath ||
    item.children.find(child => child.link === currentPath));
  const currentChildren = currentRoute.children.find(
    item => item.link === currentPath);
  const breadcrumbs = currentRoute.children.filter(
    child => child?.step <= currentChildren?.step);

  const handleLogout = () => {
    localStorage.removeItem(jwtTokens.ACCESS_TOKEN);
    dispatch(adminStore.actions.clearCurrentAdmin());
    navigate(publicUrl('/login'));
  };

  return (<Box display="flex" width="100%" height="100%">
    <Box
      style={{
        width          : '250px',
        flexDirection  : 'column',
        display        : 'flex',
        height         : '100vh',
        position       : 'fixed',
        borderRight    : '1px solid #dfdfdf',
        backgroundColor: 'white',
        zIndex         : '10'
      }}
    >
      <Toolbar/>
      <Divider/>
      <List className="menu-list">
        {menus.map((item) => {
          const activeClass = item.link === currentRoute.link ? 'active' : '';

          return (<PublicLink
            key={item.label}
            className={`menu-list__link ${activeClass}`}
            to={item.link}>
            <ListItem key={item.label} className="menu-list__item">
              <ListItemIcon className="menu-list__icon">
                {item.icon}
              </ListItemIcon>
              <ListItemText
                className="menu-list__label">{item.label}</ListItemText>
            </ListItem>
          </PublicLink>);
        })}
        <Divider/>
        <Box padding="20px" display="flex" justifyContent="center">
          <Button onClick={handleLogout} variant="contained">Log out</Button>
        </Box>
      </List>
    </Box>
    <Box paddingLeft="250px" width="100%" display="flex" flexDirection="column">
      <Breadcrumbs className="dashboard-header" aria-label="breadcrumb">
        <PublicLink
          className={`dashboard-header__link ${!breadcrumbs.length ?
            'active' :
            ''}`}
          to={currentRoute.link}>
          {currentRoute.label}
        </PublicLink>
        {breadcrumbs.map(item => {
          const breadcrumbLink = Object.entries(params)
            .reduce((prev, [ key, value ]) => {
              if (!item.params.includes(key)) return prev;

              return prev.replace(`:${key}`, value);
            }, item.link);

          return (<PublicLink key={item.link}
                              className="dashboard-header__current-link"
                              to={breadcrumbLink}>
            {item.name}
          </PublicLink>);
        })}
      </Breadcrumbs>
      <Divider/>
      <Outlet/>
    </Box>
  </Box>);
};

export default DashboardLayout;
