// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {defaultPagination} from '../../constants/pagination';

const apiKeySlice = createSlice({
  name         : 'apiKey',
  initialState : {
    items    : [],
    info     : defaultPagination,
    isLoading: false,
  },
  reducers     : {
    clearKeys(state) {
      state.items = [];
      state.info = defaultPagination;

      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(asyncActions.getKeys.fulfilled, (state, { payload }) => {
      const { docs, ...pagination } = payload.data;
      state.items = docs;
      state.info = { ...pagination };
      state.isLoading = false;
      return state;
    }).addCase(asyncActions.getKeys.pending, (state) => {
      state.isLoading = true;
    }).addCase(asyncActions.getKeys.rejected, (state) => {
      state.isLoading = false;
    }).addCase(asyncActions.refreshKey.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const key = payload.data;
      state.items = state.items.map(apiKey=>apiKey._id === key._id ? {...apiKey,...key} : apiKey);
      return state;
    }).addCase(asyncActions.refreshKey.pending, (state) => {
      state.isLoading = true;
    }).addCase(asyncActions.refreshKey.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const apiKeyStore = Object.freeze({
  ...apiKeySlice,
  asyncActions,
});

export default apiKeyStore;
