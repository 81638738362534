// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import userService from '../../services/user';

const userAsyncActions = Object.freeze({
  getUsers            : createAsyncThunk('get/users', userService.getUsers),
  getDeletionRequests : createAsyncThunk('get/deletionRequests', userService.getDeletionRequests),
  getUser             : createAsyncThunk('get/user', userService.getUser),
  getUserById         : createAsyncThunk("get/getUserById", userService.getUserById),
  suspendUser         : createAsyncThunk('get/suspendUser', userService.suspendUser),
  activateUser        : createAsyncThunk('get/activateUser', userService.activateUser),
  deactivateUser      : createAsyncThunk('get/deactivateUser', userService.deactivateUser),
  deleteTenantUser    : createAsyncThunk('delete/deleteTenantUser', userService.deleteTenantUser),
});

export default userAsyncActions;
